// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyDUhR7_ba-oJ1SpJ5cj0A0d1Q3WZ4Hy-BU',
    authDomain: 'manacla-dc3ba.firebaseapp.com',
    databaseURL: 'https://manacla-dc3ba.firebaseio.com',
    projectId: 'manacla-dc3ba',
    storageBucket: 'manacla-dc3ba.appspot.com',
    messagingSenderId: '88289724164'
  },
  manacla: {
    id1: '8461d42180edcc0',
    id2: 'bf0e2f8a12d65da49a87bb578911a73ca184035a'
  },
  parse: {
    APP_ID: '5hM8mK7wXS36AyveB4RVT2oyJ0U2XKOBOTkFcRor',
    JS_KEY: 'RVFVfKHzaphOMaf5mihwwkTeT4aYuiC0leYOajgY',
    serverURL: 'https://parseapi.back4app.com'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
