import { Card, CardType } from '../domain/card.model';

export const CARDS_INFO: (Card)[] = [
    {
        id: '7',
        youTubeVideo: 'vmZjRkGnqKw',
        title: 'Rafelito Polanco',
        description: 'Rafelito Polanco en Manacla, San Jose de las Matas, Sajoma, Santiago, RD - Julio 27, 2018',
        uploadDate: null,
        type: CardType.Video
    }, {
        id: '8',
        youTubeVideo: 'esD50pW4qKM',
        title: 'Fabia recordando cómo montar mulo',
        description: 'Fabia montando mulo en Manacla',
        uploadDate: null,
        type: CardType.Video
    }, {
        id: '9',
        youTubeVideo: 'mN_3dMfu72E',
        title: 'Fabia recordando cómo montar mulo',
        description: 'Fabia bajando de un mulo con una silla en Manacla',
        uploadDate: null,
        type: CardType.Video
    }, {
        id: '10',
        youTubeVideo: '3SE4Na5FVlU',
        title: 'Rafelito Polanco',
        description: 'Rafelito Polanco en Manacla, San Jose de las Matas, Sajoma, Santiago, RD - Julio 27, 2018',
        uploadDate: null,
        type: CardType.Video
    }, {
        id: '11',
        youTubeVideo: 'D-jkNFbN78g',
        title: 'Rafelito Polanco',
        description: 'Rafelito Polanco en Manacla, San Jose de las Matas, Sajoma, Santiago, RD - Julio 27, 2018',
        uploadDate: null,
        type: CardType.Video
    }, {
        id: '12',
        youTubeVideo: 'HgwQfKcqVwQ',
        title: 'Rafelito Polanco',
        description: 'Rafelito Polanco en Manacla, San Jose de las Matas, Sajoma, Santiago, RD - Julio 27, 2018',
        uploadDate: null,
        type: CardType.Video
    }, {
        id: '13',
        youTubeVideo: 'rwtZT9w2IsI',
        title: 'Fefita la Grande',
        description: 'Fefita la Grande en Manacla, San Jose de las Matas, Sajoma, Santiago, RD - Julio 29, 2018',
        uploadDate: null,
        type: CardType.Video
    }, {
        id: '1',
        image: 'coffee_tree.jpg',
        title: 'Café',
        description: 'La comunidad de Manacla siembra café para uso personal y para vender.',
        uploadDate: null,
        type: CardType.Image
    }, {
        id: '2',
        image: 'OldHouse.jpg',
        title: 'Casa antigua en Manacla',
        description: 'Esta casa antigua muestra algunos de los colores típicos de las casas de Manacla.',
        uploadDate: null,
        type: CardType.Image
    }, {
        id: '3',
        image: 'nature.jpg',
        title: 'Naturaleza',
        description: 'Naturaleza en su máxima expresión. Manacla es rica en toda la naturaleza que la rodea.',
        uploadDate: null,
        type: CardType.Image
    }, {
        id: '4',
        image: 't.jpg',
        title: 'Tuna (Nopal)',
        description: '',
        uploadDate: null,
        type: CardType.Image
    }, {
        id: '5',
        image: 'flower.jpg',
        title: 'Jardin de Flores',
        description: '',
        uploadDate: null,
        type: CardType.Image
    }, {
        id: '6',
        image: 'rio.jpg',
        title: 'Rio Manacla',
        description: '',
        uploadDate: null,
        type: CardType.Image
    }
];
